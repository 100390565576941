<template>
  <!--  <li v-for="item in list" :key="item.id">-->
  <!--    <div class="heard">-->
  <!--      <p>-->
  <!--        <img-->
  <!--            :src="item.headUser?item.headUser:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' "-->
  <!--            alt="用户头像"-->
  <!--            style="-->
  <!--              width: 22px;-->
  <!--              height: 22px;-->
  <!--              border-radius: 50%;-->
  <!--              margin-right: 8px;-->
  <!--            "-->
  <!--        />-->
  <!--        <span-->
  <!--            style="font-size: 12px; color: #666; font-weight: 700;margin-bottom: 10px">{{-->
  <!--            item.commentPersonName-->
  <!--          }}</span>-->
  <!--      </p>-->
  <!--    </div>-->
  <!--    <div v-show="item.replierName" style="text-align: left">-->
  <!--      <p class="CommentCon1" v-show="show" @dblclick="show = false">-->
  <!--        <span style="font-size: 12px; color: #333; text-align: left; margin-right: 4px;">回复</span>-->
  <!--        <span style="font-size: 12px; color: #666">{{ item.replierName }}：</span>-->
  <!--        <span v-if="item.markDelete === 0">{{ item.commentDetails }}</span>-->
  <!--        <span style="color: #999" v-if="item.markDelete === 1">此条评论已被删除~</span>-->
  <!--      </p>-->
  <!--      <p class="CommentCon2" v-show="!show" @dblclick="show = true">-->
  <!--        <span style="font-size: 12px; color: #333;text-align: left;margin-right: 4px;">回复</span>-->
  <!--        <span style="font-size: 12px; color: #666">{{ item.replierName }}：</span>-->
  <!--        <span v-if="item.markDelete === 0">{{ item.commentDetails }}</span>-->
  <!--        <span style="color: #999" v-if="item.markDelete === 1">此条评论已被删除~</span>-->
  <!--      </p>-->
  <!--    </div>-->
  <!--    <div v-show="!item.replierName">-->
  <!--      <p v-if="item.markDelete === 0" class="CommentCon1" v-show="show" @dblclick="show = false">-->
  <!--        <span> {{ item.commentDetails }}</span>-->
  <!--      </p>-->
  <!--      <p v-if="item.markDelete === 1" class="CommentCon1" v-show="show">-->
  <!--        <span style="color: #999">此条评论已被删除~</span>-->
  <!--      </p>-->
  <!--      <p v-if="item.markDelete === 0" class="CommentCon2" v-show="!show" @dblclick="show = true">-->
  <!--        <span>{{ item.commentDetails }}</span>-->
  <!--      </p>-->
  <!--    </div>-->
  <!--    <p class="reply">-->
  <!--      <span style="color: #999; margin-right: 10px">{{ item.commentDate }}</span>-->
  <!--      <span v-show="item.markDelete!==1" style="color: #777777; cursor: pointer; margin-right: 10px"-->
  <!--            @click="clickChild(2, item.commentId,item.commentPersonName)">回复</span>-->
  <!--      <span v-show="item.markDelete!==1 && item.commentPersonId === userId"-->
  <!--            style="color: #cc0202; cursor: pointer; font-size: 12px"-->
  <!--            @click="deleteComment(item.commentId,item.commentPersonId,item.markDelete)">删除</span>-->
  <!--    </p>-->
  <!--    <commentR-->
  <!--        @clickChild="clickChild"-->
  <!--        @deleteComment="deleteComment"-->
  <!--        :list="item.commentOfComment"-->
  <!--        style="margin-left: 30px"-->
  <!--    />-->
  <!--  </li>-->
  <van-cell class="comment-item">
    <div class="heard">
      <p>
        <img
            :src="item.headUser?item.headUser:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' "
            alt="用户头像"
            style="
                width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-right: 8px;"/>
        <span
            style="font-size: 12px; color: #666; font-weight: 700;margin-bottom: 10px">{{
            item.commentPersonName
          }}</span>
      </p>
    </div>
    <div v-show="item.replierName" style="text-align: left">
      <p class="CommentCon1" v-show="show" @dblclick="show = false">
        <span style="font-size: 12px; color: #333; text-align: left; margin-right: 4px;">回复</span>
        <span style="font-size: 12px; color: #666">{{ item.replierName }}：</span>
        <span v-if="item.markDelete === 0">{{ item.commentDetails }}</span>
        <span style="color: #999" v-if="item.markDelete === 1">此条评论已被删除~</span>
      </p>
      <p class="CommentCon2" v-show="!show" @dblclick="show = true">
        <span style="font-size: 12px; color: #333;text-align: left;margin-right: 4px;">回复</span>
        <span style="font-size: 12px; color: #666">{{ item.replierName }}：</span>
        <span v-if="item.markDelete === 0">{{ item.commentDetails }}</span>
        <span style="color: #999" v-if="item.markDelete === 1">此条评论已被删除~</span>
      </p>
    </div>
    <div v-show="!item.replierName">
      <p v-if="item.markDelete === 0" class="CommentCon1" v-show="show" @dblclick="show = false">
        <span> {{ item.commentDetails }}</span>
      </p>
      <p v-if="item.markDelete === 1" class="CommentCon1" v-show="show">
        <span style="color: #999">此条评论已被删除~</span>
      </p>
      <p v-if="item.markDelete === 0" class="CommentCon2" v-show="!show" @dblclick="show = true">
        <span>{{ item.commentDetails }}</span>
      </p>
    </div>
    <p class="reply">
      <span style="color: #999; margin-right: 10px">{{ item.commentDate }}</span>
      <span v-show="item.markDelete!==1" style="color: #777777; cursor: pointer; margin-right: 10px"
            @click="clickChild(2, item.commentId,item.commentPersonName)">回复</span>
      <span v-show="item.markDelete!==1 && item.commentPersonId === userId"
            style="color: #cc0202; cursor: pointer; font-size: 12px"
            @click="deleteComment(item.commentId,item.commentPersonId,item.markDelete)">删除</span>
    </p>
    <commentR
        @clickChild="clickChild"
        @deleteComment="deleteComment"
        v-for="item1 in item.commentOfComment"
        :key="item1.commentId"
        :item="item1"
    />
  </van-cell>
</template>

<script setup>
import {ref, defineProps, defineEmits, watch} from "vue";
import store from '@/store'

const userId = ref()
userId.value = store.state.userInfo.id
// console.log(userId.value)
watch(
    () => store.state.userInfo.id,
    (newId) => {
      // console.log(newId)
      userId.value = newId
    },
    {
      deep: true,
      immediate: true
    }
)
defineProps({
  item: {
    type: Object,
    default: () => {
    },
  },
});
// console.log(props.item);
const show = ref(true);
const emit = defineEmits(["clickChild", "deleteComment"]);
const clickChild = (grade, comId, cname) => {
  const param = {
    commentGrade: grade,
    superId: comId,
    commentPersonName: cname
  };
  emit('clickChild', param)
};
const deleteComment = (cId, pId, mark) => {
  // console.log(cId, pId, mark)
  const deleteParam = {
    commentId: cId,
    commentPersonId: pId,
    markDelete: mark
  }
  emit('deleteComment', deleteParam)
}
</script>

<style lang="scss" scoped>
.heard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 18px;

  p {
    display: flex;
    align-items: center;

    span {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }
  }
}

.CommentCon1 {
  text-align: left;
  padding-left: 30px;
  width: 318px;
  font-size: 12px;
  color: #333333;
  line-height: 14px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: -10px;
}

.CommentCon2 {
  text-align: left;
  padding-left: 30px;
  width: 318px;
  font-size: 12px;
  color: #333333;
  line-height: 14px;
  margin-top: -10px;
}

.reply {
  //margin-top: 5px;
  //margin-bottom: 16px;
  text-align: left;
  padding-left: 30px;
  box-sizing: border-box;

  span {
    font-size: 12px;
  }
}
</style>
