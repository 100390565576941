import {createApp} from 'vue'
// elemment  UI 框架
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import "lib-flexible-computer";
// import 'lib-flexible'
// import 'lib-flexible/flexible.js'
import App from './App.vue'
import router from './router'
import store from './store'
// 中文语言包 elementPlus
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// import 'swiper/css/bundle'  //"./swiper-bundle.min.css"
// 评论递归R
import commentR from './components/comment.vue'
import './assets/iconfont/iconfont.css'
// icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 1. 引入你需要的组件
import {Button, List, TextEllipsis, Popover, Grid, GridItem} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

// 屏蔽错误信息
app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;
// 全局注册
app.component('commentR', commentR)

app.use(ElementPlus, {
    locale: zhCn,
}).use(store).use(router).mount('#app')

// 3. 注册你需要的组件
app.use(Button);
app.use(List);
app.use(TextEllipsis)
app.use(Popover)
app.use(Grid)
app.use(GridItem)
