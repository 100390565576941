import {createRouter, createWebHistory} from 'vue-router'

// 导入 nprogress模块
import NProgress from 'nprogress';
// 导入 nprogress样式
import 'nprogress/nprogress.css';

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index/index.vue'),
    },
    {
        path: '/about',
        name: 'home',
        component: () => import('../views/home/home.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/loginReset.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/register/registerReset.vue'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/forgotpassword',
        name: 'forgot-password',
        component: () => import('../views/forgotPassword/forgotPasswordReset'),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/policydetail',
        name: 'policydetail',
        component: () => import('../views/layout/policy/policyDetail/policyDetail.vue'),
        meta: {
            title: '政策详情'
        }
    },
    {
        path: '/layout',
        name: 'layout',
        component: () => import('../views/layout/layout.vue'),
        children: [
            {
                path: '/resource',
                name: 'resource',
                component: () => import('../views/layout/resource/resource.vue'),
                meta: {
                    title: '优待资源'
                }
            },
            {
                path: '/policy',
                name: 'policy',
                component: () => import('../views/layout/policy/policy.vue'),
                meta: {
                    title: '国家政策'
                }
            },
            {
                path: '/shop',
                name: 'shop',
                component: () => import('../views/layout/shop/shop.vue'),
                meta: {
                    title: '商城'
                }
            },
            {
                path: '/circle',
                name: 'circle',
                component: () => import('../views/layout/circle/circle.vue'),
                meta: {
                    title: '圈子'
                }
            },
        ]
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/user.vue'),
        children: [
            {
                path: '/myinfo',
                name: 'myinfo',
                component: () => import('../views/user/userInfo/userInfo.vue'),
                meta: {
                    title: '个人资料'
                }
            },
            {
                path: '/authorization',
                name: 'authorization',
                component: () => import('../views/user/trueAuthorization/trueAuthorization.vue'),
                meta: {
                    title: '实名认证'
                }
            },
            {
                path: '/reslike',
                name: 'reslike',
                component: () => import('../views/user/resLike/resLike.vue'),
                meta: {
                    title: '资源点赞'
                }
            },
            {
                path: '/policylike',
                name: 'policylike',
                component: () => import('../views/user/policyLike/policyLike.vue'),
                meta: {
                    title: '政策点赞'
                }
            },
            {
                path: '/rescollections',
                name: 'rescollections',
                component: () => import('../views/user/resCollections/resCollections.vue'),
                meta: {
                    title: '资源收藏'
                }
            },
            {
                path: '/policycollections',
                name: 'policycollections',
                component: () => import('../views/user/policyCollections/policyCollections.vue'),
                meta: {
                    title: '政策收藏'
                }
            },
            {
                path: '/comment',
                name: 'comment',
                component: () => import('../views/user/myComment/myComment.vue'),
                meta: {
                    title: '我的评论'
                }
            },
        ]
    },
    {
        path: '/service-agreement',
        name: 'service-agreement',
        component: () => import('../views/serviceAgreement/serviceAgreement.vue'),
        meta: {
            title: '服务协议'
        }
    },
    {
        path: '/suggestion-agreement',
        name: 'suggestion-agreement',
        component: () => import('../views/suggestion/suggestion.vue'),
        meta: {
            title: '建议投诉'
        }
    },
    {
        path: '/mobile-page',
        component: () => import('../views/zotherPage/MobilePage.vue')
    },
    //论坛
    {
        path: '/forum',
        component: () => import('../views/forum/forum.vue')
    },
]

const router = createRouter({
    mode: 'hash',
    history: createWebHistory(),
    routes
})

// 默认网页标签
const defaultTitle = '退役军人优待证-战友你好';
// 路由拦截
router.beforeEach((to, from, next) => {
    // 开启
    NProgress.start()
    // 动态网页标签
    document.title = to.meta.title ? to.meta.title + '-退役军人优待证' : defaultTitle;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // console.log(isMobile)
    if (to.path === '/' && isMobile) {
        // 只在真正需要重定向时返回新的位置
        next('/mobile-page');
    } else {
        // 其他情况直接调用判断登录是否过期 next()
        next();
    }
});

router.afterEach(() => {
    // 关闭
    NProgress.done()
})
export default router
