<template>
  <router-view/>
</template>
<script setup>
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
// 问题的
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>
<style lang="scss">
@import "./assets/css/reset.css";

#nprogress {
  .bar {
    background: #376bf4 !important;
    z-index: 99999 !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

//*::-webkit-scrollbar {
//  display: none;
//}
//
//* {
//  scrollbar-width: none;
//}
//
//* {
//  -ms-overflow-style: none;
//}
</style>
