import {createStore} from 'vuex'

export default createStore({
    id: "GlobalState",
    state: {
        token: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).user.token : '',//token
        userInfo: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).user : '', //用户信息
        resourceList: JSON.parse(sessionStorage.getItem('ResourceList')) ? JSON.parse(sessionStorage.getItem('ResourceList')) : '',//资源列表
        policyList: JSON.parse(sessionStorage.getItem('PolicyList')) ? JSON.parse(sessionStorage.getItem('PolicyList')) : '',//政策列表
        commentID: JSON.parse(sessionStorage.getItem('CommentId')) ? JSON.parse(sessionStorage.getItem('CommentId')) : '',//政策列表
    },
    getters: {},
    mutations: {
        // 存储用户token
        setUserToken(state, res) {
            state.token = JSON.parse(res).user.token
            state.userInfo = JSON.parse(res).user
        },
        // 存储资源
        setResource(state, res) {
            state.resourceList = JSON.parse(res)
        },
        // 存储政策和方法
        setPolicy(state, res) {
            state.policyList = JSON.parse(res)
        },
        // 存储政策列表
        setCommentId(state, res) {
            state.commentID = JSON.parse(res)
        }
    },
    actions: {},
    modules: {}
})
